import * as React from 'react'
import { Router } from '@reach/router'

import SpeedCanvas from '@components/SpeedCanvas'
import { RoomWrapper } from '@components/Room'
import { RoomType } from '@stores/models/Room'

const SpeedPage = () => {
  return (
    <Router>
      <RoomWrapper path="/speed/:roomId" type={RoomType.SPEED}>
        <SpeedCanvas path="/" />
      </RoomWrapper>
    </Router>
  )
}

export default SpeedPage
